<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-card-text>{{ $t("Name") }}</b-card-text>
            </b-col>
            <b-col
              v-for="(item , index) in language"
              :key="index+'name'"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="item.name"
                :label-for="item.name+'name'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name+'name'"
                  :vid="item.name+'name'"
                >
                  <b-form-input
                    v-if="name[index]"
                    :id="item.name+'name'"
                    :name="item.name+'name'"
                    :value="name[index]['value']"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="item.name"
                    @input="inputLanguage($event,item.lang ,index, 'name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-card-text>{{ $t("Detail") }}</b-card-text>
            </b-col>
            <b-col
              v-for="(item , index) in language"
              :key="index+'detail'"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="item.name"
                :label-for="item.name+'detail'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name+'detail'"
                  :vid="item.name+'detail'"
                >
                  <b-form-textarea
                    v-if="detail[index]"
                    :id="item.name+'detail'"
                    :name="item.name+'detail'"
                    :value="detail[index]['value']"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="item.name"
                    @input="inputLanguage($event,item.lang ,index, 'detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Service Area')"
                label-for="register-serviceArea"
              >
                <validation-provider
                  #default="{ errors }"
                  name="serviceArea"
                  vid="serviceArea"
                  rules=""
                >
                  <v-select
                    id="register-serviceArea"
                    v-model="serviceAreaId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="serviceAreaData"
                    :reduce="(serviceAreaData) => serviceAreaData._id"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Amount')"
                label-for="register-amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Amount"
                  vid="amount"
                  rules=""
                >
                  <b-form-input
                    id="register-amount"
                    v-model="amount"
                    name="register-amount"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Amount')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Option')"
                label-for="register-option"
              >
                <validation-provider
                  #default="{ errors }"
                  name="option"
                  vid="option"
                  rules="required"
                >
                  <v-select
                    id="register-option"
                    v-model="option"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="Option"
                    :reduce="(Option) => Option.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Price Reduction')"
                label-for="register-priceReduction"
              >
                <validation-provider
                  #default="{ errors }"
                  name="priceReduction"
                  vid="priceReduction"
                  rules="required"
                >
                  <v-select
                    id="register-priceReduction"
                    v-model="priceReduction"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionPriceReduction"
                    :reduce="(OptionPriceReduction) => OptionPriceReduction.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Service Type')"
                label-for="register-serviceType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="serviceType"
                  vid="serviceType"
                  rules="required"
                >
                  <v-select
                    id="register-serviceType"
                    v-model="serviceType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionServiceType"
                    :reduce="(OptionServiceType) => OptionServiceType.value"
                    class="select-size-lg"
                    @input="changeServiceType($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Partner')"
                label-for="register-Partner"
              >
                <validation-provider
                  #default="{ errors }"
                  name="respDataPartner"
                  vid="respDataPartner"
                  rules="required"
                >
                  <v-select
                    id="register-respDataPartner"
                    v-model="profilePartnerId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="firstName"
                    :options="respDataPartner"
                    :reduce="(respDataPartner) => respDataPartner._id"
                    :filterable="false"
                    class="select-size-lg"
                    @search="onSearchPartner"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPagePartner <= 1 ? true :false"
                            @click="nextPagePartner(currentPagePartner -1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >
                            {{ currentPagePartner }}
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="(currentPagePartner * perPagePartner) >= queriedItemsPartner ? true :false"
                            @click="nextPagePartner(currentPagePartner + 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                            />
                          </b-button>
                        </b-col>
                      </b-row>

                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Limit Promotion')"
                label-for="register-limitPromotion"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Limit Promotion"
                  vid="limitPromotion"
                  rules=""
                >
                  <b-form-input
                    id="register-limitPromotion"
                    v-model="limitPromotion"
                    name="register-limitPromotion"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Limit Promotion')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Start Date')"
                label-for="start-date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="StartDate"
                  vid="startDate"
                  rules="required"
                >
                  <flat-pickr
                    v-model="start"
                    class="form-control"
                    :config="configs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">

              <b-form-group
                :label="$t('End Date')"
                label-for="expire-date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="EndDate"
                  vid="endDate"
                  rules="required"
                >
                  <flat-pickr
                    v-model="end"
                    class="form-control"
                    :config="configs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t("Submit") }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import {
  BButton, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInput,
  // BLink,
  BImg, BInputGroup, BInputGroupAppend, BLink, BMedia, BMediaAside,
  // BMediaAside,
  BMediaBody, BRow, BSidebar, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

import store from '@/store'
import debounce from 'debounce'
import vSelect from 'vue-select'

// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'

export default {
  components: {
    flatPickr,
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    // BMediaAside,
    BMediaBody,
    // BLink,
    BImg,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BLink,
    BMediaAside,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameServiceArea: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      profilePartnerId: null,
      searchPartner: '',
      currentPagePartner: 1,
      perPagePartner: 10,
      image: '',
      name: [],
      detail: [],
      serviceAreaId: null,
      option: '',
      amount: '',
      priceReduction: '',
      serviceType: '',
      limitPromotion: '',
      start: '',
      end: '',
      configs: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        locale: Thai,
      },
      required,
    }
  },
  computed: {
    Option() {
      return [
        { name: this.$t('Constant'), value: 'constant' },
        { name: this.$t('Percent'), value: 'percent' },
      ]
    },
    OptionPriceReduction() {
      return [
        { name: this.$t('Shipping Cost'), value: 'shipping_cost' },
        { name: this.$t('Fare'), value: 'fare' },
        { name: this.$t('Option Value'), value: 'option_value' },
        { name: this.$t('Product Cost'), value: 'product_cost' },
      ]
    },
    OptionServiceType() {
      return [
        { name: this.$t('Store Grocery'), value: 'store_grocery' },
        { name: this.$t('Store Food'), value: 'store_food' },
        { name: this.$t('Store Queue'), value: 'store_queue' },
        { name: this.$t('Driver Call'), value: 'driver_call' },
        { name: this.$t('Services'), value: 'services' },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
    serviceAreaData() {
      return store.state[this.storeModuleNameServiceArea].respData != null ? store.state[this.storeModuleNameServiceArea].respData.data : []
    },
    respDataPartner() {
      return store.state[this.storeModuleName].respDataPartner != null ? store.state[this.storeModuleName].respDataPartner.data : []
    },
    queriedItemsPartner() {
      return store.state[this.storeModuleName].respDataPartner != null ? store.state[this.storeModuleName].respDataPartner.max : 0
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id,
            image,
            name,
            detail,
            serviceAreaId,
            profilePartnerId,
            option,
            amount,
            priceReduction,
            serviceType,
            limitPromotion,
            start,
            end,
          } = this.data
          this.dataId = _id
          this.image = image
          this.name = name
          this.detail = detail
          this.serviceAreaId = serviceAreaId
          if (profilePartnerId) {
            this.profilePartnerId = profilePartnerId
          }
          this.option = option
          this.amount = amount
          this.priceReduction = priceReduction
          this.serviceType = serviceType
          this.changeServiceType(serviceType)
          this.limitPromotion = limitPromotion
          this.start = start
          this.end = end
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.name[index].lang)
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.detail.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.detail[index].lang)
              if (indexLanguage < 0) {
                this.detail.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  created() {
    this.onSearchPartner = debounce(this.onSearchPartner, 500)
  },
  methods: {
    nextPagePartner(page) {
      this.currentPagePartner = page
      this.profilePartnerId = null
      const obj = {
        serviceType: this.serviceType,
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
      }
      store
        .dispatch(`${this.storeModuleName}/getPartner`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    changeServiceType(value) {
      this.serviceType = value
      this.searchPartner = ''
      this.currentPagePartner = 1
      const obj = {
        serviceType: this.serviceType,
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
      }
      store
        .dispatch(`${this.storeModuleName}/getPartner`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    onSearchPartner(query) {
      this.searchPartner = query
      this.currentPagePartner = 1
      const obj = {
        serviceType: this.serviceType,
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
      }
      store
        .dispatch(`${this.storeModuleName}/getPartner`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    inputLanguage(input, lang, index, type) {
      console.log(input, lang, index)
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
        console.log(this.name)
      } else if (type === 'detail') {
        if (this.detail[index].lang === lang) {
          this.detail[index].value = input
        }
        console.log(this.detail)
      }
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-')
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.detail = []
      this.serviceAreaId = null
      this.option = ''
      this.amount = ''
      this.priceReduction = ''
      this.serviceType = ''
      this.limitPromotion = ''
      this.start = ''
      this.end = ''
      // this.$refs.refInputEl.reset()
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const startDate = this.start.split('-')
          const endDate = this.end.split('-')
          const obj = {
            id: this.dataId,
            image: this.image,
            name: this.name,
            detail: this.detail,
            serviceArea: this.serviceAreaId,
            profilePartnerId: this.profilePartnerId,
            categoryPromotionId: this.$route.params.id,
            option: this.option,
            amount: this.amount,
            priceReduction: this.priceReduction,
            serviceType: this.serviceType,
            limitPromotion: this.limitPromotion,
            // eslint-disable-next-line radix
            start: this.formatDate(new Date(parseInt(startDate[2]), parseInt(startDate[1]) - 1, parseInt(startDate[0]))),
            // eslint-disable-next-line radix
            end: this.formatDate(new Date(parseInt(endDate[2]), parseInt(endDate[1]) - 1, parseInt(endDate[0]))),
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
